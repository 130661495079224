import styled from "@emotion/styled"
import { navigate } from "gatsby"
import React, { useContext, useEffect, useState } from "react"
import { Box, Flex } from "theme-ui"
import Button from "../components/base/buttons"
import { HorizontalHairline } from "../components/base/hairline"
import Input from "../components/base/input-field"
import Text from "../components/base/text/text"
import Cart from "../components/cart"
import SEO from "../components/seo"
import StoreContext from "../context/store-context"
import useWindowSize from "../hooks/use-window-size"
import {
  trackCartViewed,
  trackCheckoutInitialized
} from "../services/analytics"
import {
  formatCartDiscount,
  formatCartSubtotal,
  formatCartTotal
} from "../util/prices"


const Container = styled(Flex)`
  flex-direction: column;
  width: 100%;
  box-sizing: content-box;
  height: calc(100vh - 70px);

  .left {
    border-bottom: ${(props) => props.theme.borders.thin};
  }

  .center {
    flex: 1;
  }

  .right {
    border-top: ${(props) => props.theme.borders.thin};
  }

  ${(props) => props.theme.bp.desktop} {
    border: ${(props) => props.theme.borders.thin};
    width: 90%;
    max-width: 1200px;
    display: flex;
    flex-direction: row;
    height: 600px;

    .left,
    .right {
      width: 22%;
    }

    .left {
      border-right: ${(props) => props.theme.borders.thin};
    }

    .right {
      margin-right: -1px;
      border-top: none;
      border-left: ${(props) => props.theme.borders.thin};
    }
  }
`

const ApplyPromoButton = styled(Text)`
  display: flex;
  padding-top: 0px;
  justify-content: center;
  align-items: center;
  width: 30%;
  height: 100%;
`

const RemoveIcon = styled.div`
  cursor: pointer;
  display: inline-block;
  margin-left: 10px;
`

const CartPage = () => {
  const { cart, updateLineItem, addDiscount, removeDiscount } =
    useContext(StoreContext)
  const { width } = useWindowSize()
  const [showPromo, setShowPromo] = useState(false)
  const [couponCode, setCouponCode] = useState()

  useEffect(() => {
    trackCartViewed(cart)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Container my={["0px", "70px"]} mx={["0px", "auto"]} marginTop={["0px", "60px"]}>
      <SEO
        title={"Cart – Palmes"}
        description={"Palmes Tennis Society"}
      />
      {width > 767 && (
        <Flex
          className="left"
          sx={{ justifyContent: "flex-end", flexDirection: "column" }}
        >
          <Button
            variant="primary"
            onClick={() => navigate("/all")}
            py="0px"
            sx={{
              height: "35px",
              borderRadius: "0",
              paddingTop: "0px",
              paddingBottom: "0px",
            }}
          >
            <Text sx={{ fontSize: 0 }}>Return to shop</Text>
          </Button>
        </Flex>
      )}
      <Box
        className="center"
        sx={{ overflow: "scroll", height: ["100%", "600px"] }}
      >
        <Cart width={width} cart={cart} updateLineItem={updateLineItem} />
      </Box>
      {cart.discounts?.length === 0 && width < 768 && (
        <Flex
          sx={{ flexDirection: "column", width: "100%", alignItems: "left" }}
          px={2}
          py={[3, 2]}
        >
          <Text
            onClick={() => setShowPromo(true)}
            sx={{ fontSize: ["12px", "10px"] }}
          >
            {showPromo ? "Promo code" : "Apply promo code"}
          </Text>
          {showPromo && (
            <Flex>
              <Input
                sx={{ width: "70%", fontSize: ["12px", "10px"] }}
                onChange={(e) => setCouponCode(e.target.value)}
                value={couponCode}
              />
              <ApplyPromoButton
                sx={{ fontSize: ["12px", "10px"] }}
                onClick={() => addDiscount(couponCode)}
              >
                Apply
              </ApplyPromoButton>
            </Flex>
          )}
        </Flex>
      )}
      <Flex
        className="right"
        sx={{ justifyContent: "flex-end", flexDirection: "column" }}
      >
        {cart.discounts?.length === 0 && width > 768 && (
          <Flex
            sx={{ flexDirection: "column", width: "100%", alignItems: "left" }}
            px={2}
            py={[2]}
          >
            <Text
              onClick={() => setShowPromo(true)}
              sx={{ fontSize: ["12px", "10px"] }}
            >
              {showPromo ? "Promo code" : "Apply promo code"}
            </Text>
            {showPromo && (
              <Flex>
                <Input
                  sx={{
                    width: "70%",
                    fontSize: ["12px", "10px"],
                  }}
                  onChange={(e) => setCouponCode(e.target.value)}
                  value={couponCode}
                />
                <ApplyPromoButton
                  sx={{ fontSize: ["12px", "10px"] }}
                  onClick={() => addDiscount(couponCode)}
                >
                  Apply
                </ApplyPromoButton>
              </Flex>
            )}
          </Flex>
        )}
        <HorizontalHairline sx={{ display: ["none", "initial"] }} />
        <Flex sx={{ justifyContent: "space-between" }} px={2} py={[3, 2]}>
          <Text sx={{ fontSize: ["12px", "10px", "10px"] }}>
            Subtotal {cart.taxRate === 0 ? "" : "(incl. VAT) "}
          </Text>
          <Text sx={{ fontSize: ["12px", "10px", "10px"] }}>
            {formatCartSubtotal(cart)}
          </Text>
        </Flex>
        {cart.discounts?.length ? (
          <>
            <HorizontalHairline />
            <Flex sx={{ justifyContent: "space-between" }} px={2} py={[3, 2]}>
              <Text sx={{ fontSize: ["12px", "10px", "10px"] }}>
                Discount: {cart.discounts[0].code}
                <RemoveIcon
                  onClick={() => removeDiscount(cart.discounts[0].code)}
                >
                  &#215;
                </RemoveIcon>
              </Text>
              <Text sx={{ fontSize: ["12px", "10px", "10px"] }}>
                {formatCartDiscount(cart)}
              </Text>
            </Flex>
          </>
        ) : null}
        <HorizontalHairline />
        <Flex sx={{ justifyContent: "space-between" }} px={2} py={[3, 2]}>
          <Text sx={{ fontSize: ["12px", "10px", "10px"] }}>Total </Text>
          <Text sx={{ fontSize: ["12px", "10px", "10px"] }}>
            {formatCartTotal(cart)}
          </Text>
        </Flex>
        <Button
          variant="primary"
          onClick={() => {
            trackCheckoutInitialized(cart)
            navigate("/checkout")
          }}
          sx={{
            height: ["50px", "35px"],
            borderRadius: "0",
            paddingTop: "0px",
            paddingBottom: "0px",
          }}
        >
          <Text sx={{ fontSize: 0 }}>Proceed to check out</Text>
        </Button>
      </Flex>
    </Container>
  )
}

export default CartPage
